import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

const BlogList = (props) => {
  let blogs = props[0].Data;
  let { singleBlogLink } = props[0].Info;

  const truncateTitle = (title, maxLength) => {
    return title.length > maxLength ? title.slice(0, maxLength) + "..." : title;
  };

  return (
    <div className="container mt-90 rtl">
      <div className="row">
        {blogs.map((item) => (
          <div className="col-lg-4 col-sm-12 pr-30 mb-50" key={item.Id}>
            <div className="card-grid-style-4">
              <span className="tag-dot">Company</span>
              <a href={`/${singleBlogLink}/${item.Id}`} legacyBehavior>
                <a className="text-heading-4">
                  {" "}
                  {truncateTitle(item.TitleEn, 60)}
                </a>
              </a>
              <div className="grid-4-img">
                <a href={`/${singleBlogLink}/${item.Id}`} legacyBehavior>
                  <a>
                    <img
                      src={`${process.env.REACT_APP_API_URL}/uploads/${item.Thumbnail}`}
                      alt={truncateTitle(item.TitleEn, 60)}
                    />
                  </a>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="mt-20 mb-30 text-center">
        <Link href="" legacyBehavior>
          <a className="btn btn-black icon-arrow-right-white">
            Load more posts
          </a>
        </Link>
      </div> */}
    </div>
  );
};

export default BlogList;
