import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ContactForm = (props) => {
  const { PhoneNumber, Email, Address, companyName } = props[0].Info;
  const { t } = useTranslation();

  // State to hold form data
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  // State to hold form submission status
  const [status, setStatus] = useState("");

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/form/contact`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setStatus(t("messageSentSuccess"));
      }
    } catch (error) {
      setStatus(t("messageSentError"));
      console.error(error);
    }
  };

  return (
    <section className="section-box">
      <div className="container mb-20 mt-140">
        <div className="map-container mb-40">
          <iframe
            width="100%"
            height="400"
            style={{ border: 0, borderRadius: "16px" }}
            loading="lazy"
            allowFullScreen
            src="https://www.openstreetmap.org/export/embed.html?bbox=55.3320%2C25.2570%2C55.3339%2C25.2585&layer=mapnik&marker=25.257051003197397%2C55.33292166587499"
          ></iframe>
        </div>

        <div className="bdrd-58 box-gray-100 icon-wave">
          <div className="row">
            <div className="col-lg-12 mb-60">
              <span className="text-body-capitalized text-uppercase">
                {t("contactUs")}
              </span>
              <h2 className="text-heading-3 color-gray-900 mt-10">
                {t("readyToGetStarted")}
              </h2>
              <p className="text-body-text color-gray-600 mt-20">
                {t("investmentTip")}
                <br className="d-lg-block d-none" />
                {t("businessDream")}
              </p>
            </div>
            <div className="col-lg-4 mb-40">
              <h4 className="text-heading-6 color-gray-900 icon-home mb-10 mt-10">
                {companyName}
              </h4>
              <p className="text-body-text color-gray-600">{Address}</p>
              <p className="text-body-text color-gray-600">{PhoneNumber}</p>
              <p className="text-body-text color-gray-600">{Email}</p>
            </div>
            <div className="col-lg-8">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder={t("enterYourName")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder={t("companyOptional")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder={t("yourEmail")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder={t("phoneNumber")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder={t("tellAboutYourself")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mt-15">
                  <button
                      className="btn btn-black icon-arrow-right-white mr-40 mb-20"
                      type="submit">
                      {t("sendMessage")}
                    </button>
                  </div>
                  <div className="col-lg-6 mt-30">
                    
                    <span className="text-body-text-md color-gray-500">
                      {t("agreementNotice")}
                    </span>
                  </div>
                  {status && (
                    <div className="col-lg-12 mt-10">
                      <p>{status}</p>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
