import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Sidebar from "./layout/Sidebar";
import BackToTop from "./elements/BackToTop";
import { useTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa";

const Header = (props) => {
  const [openClass, setOpenClass] = useState("");
  const { i18n } = useTranslation();
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);

  const handleOpen = () => {
    document.body.classList.add("mobile-menu-active");
    setOpenClass("sidebar-visible");
  };

  const handleRemove = () => {
    if (openClass === "sidebar-visible") {
      setOpenClass("");
      document.body.classList.remove("mobile-menu-active");
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
    setShowLanguageMenu(false);
  };

  let { headerStyle } = props;
  let { Logo, SmallLogo, shadePercentage, buttonText, buttonLink } =
    props[0].Info;
  let links = props[0].Links;
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  }, [scroll]);

  useEffect(() => {
    if (!localStorage.getItem("i18nextLng")) {
      changeLanguage("en");
    }
  }, []);
  return (
    <>
      <div className={openClass && "body-overlay-1"} onClick={handleRemove} />
      <header
        className={
          scroll
            ? `${headerStyle} header sticky-bar stick`
            : `${headerStyle} header sticky-bar`
        }
      >
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link to="/" legacyBehavior>
                  <a className="d-flex">
                    {headerStyle ? (
                      <img
                        alt={props.pageTitle}
                        src={`${process.env.REACT_APP_API_URL}/${SmallLogo}`}
                        style={{ filter: `grayscale(${shadePercentage}%)` }}
                      />
                    ) : (
                      <img
                        alt={props.pageTitle}
                        src={`${process.env.REACT_APP_API_URL}/${Logo}`}
                        style={{ filter: `grayscale(${shadePercentage}%)` }}
                        width={250}
                      />
                    )}
                  </a>
                </Link>
              </div>
              <div
                className="language-switcher"
                onMouseEnter={() => setShowLanguageMenu(true)}
                onMouseLeave={() => setShowLanguageMenu(false)}
                style={{ position: "relative", cursor: "pointer", padding: "10px" }} // Added padding here
                >
                <FaGlobe color="#000000" size={20} />
                {showLanguageMenu && (
                  <div
                    className="language-dropdown"
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      backgroundColor: "#fff",
                      borderRadius: "5px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      zIndex: 10,
                    }}
                  >
                    <div
                      onClick={() => changeLanguage("en")}
                      className="language-option"
                      style={{
                        padding: "8px 12px",
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer",
                        color: "#333",
                      }}
                    >
                      English
                    </div>
                    <div
                      onClick={() => changeLanguage("ar")}
                      className="language-option"
                      style={{
                        padding: "8px 12px",
                        cursor: "pointer",
                        color: "#333",
                      }}
                    >
                      عربي
                    </div>
                  </div>
                )}
              </div>
              <div className="header-nav">
                <nav className="nav-main-menu d-none d-xl-block">
                  <ul className="main-menu">
                    {links.map((link) => (
                      <li key={link.Name}>
                        <a href={link.Url} legacyBehavior>
                          <a className="active">{link.Name}</a>
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
                <div
                  className="burger-icon burger-icon-white"
                  onClick={handleOpen}
                >
                  <span className="burger-icon-top" />
                  <span className="burger-icon-mid" />
                  <span className="burger-icon-bottom" />
                </div>
              </div>
            </div>
            <div className="header-right">
              <div className="block-signin">
                <a href={buttonLink} legacyBehavior>
                  <a className="btn btn-default hover-up">{buttonText}</a>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Sidebar openClass={openClass} links={[...links]} logo={SmallLogo} />
    </>
  );
};

export default Header;
