import { useState } from "react";

function Faq2(props) {
  return (
    <section className="section-box mt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-12">
            <ul className="list-icons-2">
              <li>
                <h3 className="text-heading-5 mb-10">
                  Can I see the demo before purchasing?
                </h3>
                <p className="text-body-text color-gray-500">
                  Etiam amet mauris suscipit in odio integer congue metus vitae
                  arcu mollis blandit ultrice ligula egestas and magna suscipit
                  lectus magna suscipit luctus blandit vitae
                </p>
              </li>
              <li>
                <h3 className="text-heading-5 mb-10">
                  Can I use your system on different devices?
                </h3>
                <p className="text-body-text color-gray-500">
                  Etiam amet mauris suscipit in odio integer congue metus vitae
                  arcu mollis blandit ultrice ligula egestas and magna suscipit
                  lectus magna suscipit luctus blandit vitae
                </p>
              </li>
              <li>
                <h3 className="text-heading-5 mb-10">
                  Can I import my sitemap to your system?
                </h3>
                <p className="text-body-text color-gray-500">
                  An enim nullam tempor sapien gravida a donec ipsum enim an
                  porta justo integer at velna vitae auctor integer congue undo
                  magna at pretium purus pretium
                </p>
              </li>
              <li>
                <h3 className="text-heading-5 mb-10">
                  Can I cancel my subscription at any time?
                </h3>
                <p className="text-body-text color-gray-500">
                  An enim nullam tempor sapien gravida a donec ipsum enim an
                  porta justo integer at velna vitae auctor integer congue undo
                  magna at pretium purus pretium
                </p>
              </li>
              <li>
                <h3 className="text-heading-5 mb-10">
                  How can I switch my subscription between essential, and
                  premium plan
                </h3>
                <p className="text-body-text color-gray-500">
                  Cubilia laoreet augue egestas and luctus donec curabite diam
                  vitae dapibus libero and quisque gravida donec and neque.
                </p>
              </li>
              <li>
                <h3 className="text-heading-5 mb-10">
                  Is there an additional discount when paid annually?
                </h3>
                <p className="text-body-text color-gray-500">
                  Cubilia laoreet augue egestas and luctus donec curabite diam
                  vitae dapibus libero and quisque gravida donec and neque.
                  Blandit justo aliquam molestie nunc sapien
                </p>
              </li>
              <li>
                <h3 className="text-heading-5 mb-10">
                  I have an issue with my account
                </h3>
                <ul className="text-body-text">
                  <li>
                    Etiam amet mauris suscipit sit amet in odio. Integer congue
                    leo metus. Vitae arcu mollis blandit ultrice ligula
                  </li>
                  <li>
                    An enim nullam tempor sapien gravida donec congue leo metus.
                    Vitae arcu mollis blandit integer at velna
                  </li>
                </ul>
              </li>
              <li>
                <h3 className="text-heading-5 mb-10">
                  What happens if I don’t renew my license after one year?
                </h3>
                <ul className="text-body-text">
                  <li>
                    Etiam amet mauris suscipit sit amet in odio. Integer congue
                    leo metus. Vitae arcu mollis blandit ultrice ligula
                  </li>
                  <li>
                    An enim nullam tempor sapien gravida donec congue leo metus.
                    Vitae arcu mollis blandit integer at velna
                  </li>
                </ul>
              </li>
              <li>
                <h3 className="text-heading-5 mb-10">
                  Do you have a free trial?
                </h3>
                <p className="text-body-text color-gray-500" />
                <ul className="text-body-text">
                  <li>Fringilla risus, luctus mauris orci auctor purus</li>
                  <li>
                    Quaerat sodales sapien euismod blandit purus and ipsum
                    primis in cubilia laoreet augue luctus
                  </li>
                </ul>
                <p />
              </li>
              <li>
                <h3 className="text-heading-5 mb-10">
                  What kind of payment methods do you provide?
                </h3>
                <p className="text-body-text color-gray-500" />
                <ul className="text-body-text">
                  <li>Fringilla risus, luctus mauris orci auctor purus</li>
                  <li>Quaerat sodales sapien euismoda laoreet augue luctus</li>
                </ul>
                <p />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq2;
