import React from "react";
import { useTranslation } from "react-i18next";

const ContactBanner = (props) => {
  const { t } = useTranslation(); // Initialize translation hook
  
  let { contactTitle, mainFontColor, subFontColor } = props[0].Info;

  return (
    <section className="section-box">
      <div className="banner-hero banner-breadcrums">
        <div className="container text-center">
          <h1 className="text-heading-2 color-gray-1000 mb-20">
            {t("contactUs")}
          </h1>
          <p
            className="text-body-text color-gray-500"
            style={{ color: subFontColor }}
          >
            {contactTitle}
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactBanner;
