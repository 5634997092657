import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SingleBlog(props) {
  const { t } = useTranslation(); // Initialize translation hook
  
  let data = props[0].Data;
  let { backgroundImg, title } = props[0].Info;
  
  return (
    <>
      <section className="section-box">
        <div
          className="banner-hero banner-head-image"
          style={{ background: "url(assets/imgs/page/blog/single/banner.png)" }}
        >
          <div className="container">
            <div className="text-center">
              <h1 className="text-heading-1 color-white mt-30">{title}</h1>
              <br></br>
              <span className="tag-1 bg-6 color-green-900">{t("readAbout")}</span>
            </div>
          </div>
        </div>
      </section>
      <section className="section-box mt-50 mb-50 rtl">
        <div className="container">
          <div className="row">
            <div className="col-lg-1 col-md-12" />
            <div className="col-lg-1 col-md-2 col-sm-2 col-3 text-center">
              <div className="social-sticky">
                <h3 className="text-heading-6 color-gray-400 mb-20 mt-5">{t("share")}</h3>
                <Link to="https://facebook.com" target="_blank">
                  <a className="share-social share-fb"></a>
                </Link>
                <br />
                <Link to="https://twitter.com" target="_blank">
                  <a className="share-social share-tw"></a>
                </Link>
                <br />
                <Link to="https://www.pinterest.com" target="_blank">
                  <a className="share-social share-pi"></a>
                </Link>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-10 col-9 rtl">
              <div className="text-summary">{data.titleEn}</div>
              <img
                style={{ width: "100%", height: "250px", borderRadius: "15px" }}
                src={`${process.env.REACT_APP_API_URL}/uploads/${data.thumbnail}`}
                alt={data.titleEn}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6 col-md-7 col-sm-7 col-7">
                  <div className="blog-img-user">
                    <div className="img-user img-user-round">
                      <img src="/assets/imgs/th.jpg" alt={data.author || "Al Noblaa"} />
                    </div>

                    <h4 className="text-body-lead color-gray-900">
                      {t("writtenBy")} {data.author || "Al Noblaa"}
                    </h4>
                    <p className="text-body-small color-gray-500">
                      {new Date(data.createDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <div className="single-detail mt-50">
                <div dangerouslySetInnerHTML={{ __html: data.contentEn }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SingleBlog;
