import { Link } from "react-router-dom";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const Sidebar = ({ openClass, links, logo }) => {
  console.log(links);
  const [isActive, setIsActive] = useState({
    status: false,
    key: "",
  });

  const handleToggle = (key) => {
    if (isActive.key === key) {
      setIsActive({
        status: false,
      });
    } else {
      setIsActive({
        status: true,
        key,
      });
    }
  };
  return (
    <>
      <div
        className={`mobile-header-active mobile-header-wrapper-style perfect-scrollbar ${openClass}`}
      >
        <PerfectScrollbar className="mobile-header-wrapper-inner">
          <div className="mobile-header-top">
            <div className="user-account">
              <img src={`${process.env.REACT_APP_API_URL}/${logo}`} alt="Agon" />
              <div className="content">
                <h6 className="user-name">
                  Al Noblaa Group
                </h6>
                {/* <p className="font-xs text-muted">You have 5 new messages</p> */}
              </div>
            </div>
          </div>
          <div className="mobile-header-content-area">
            <div className="perfect-scroll">
              <div className="mobile-menu-wrap mobile-header-border">
                <nav>
                  <ul className="mobile-menu font-heading">
                    {links.map((link, index) => (
                      <li
                        className={
                          isActive.key == index + 1
                            ? "has-children active"
                            : "has-children"
                        }
                        key={link.Name}
                      >
                        <a href={link.Url} legacyBehavior>
                          <a>{link.Name}</a>
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
              {/* <div className="mobile-account">
                <h6 className="mb-10">Your Account</h6>
                <ul className="mobile-menu font-heading">
                  <li>
                    <Link href="/#" legacyBehavior>
                      <a>Profile</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/#" legacyBehavior>
                      <a>Work Preferences</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/#" legacyBehavior>
                      <a>My Boosted Shots</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/#" legacyBehavior>
                      <a>My Collections</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/#" legacyBehavior>
                      <a>Account Settings</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/#" legacyBehavior>
                      <a>Go Pro</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/page-login" legacyBehavior>
                      <a>Sign Out</a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="site-copyright color-gray-400">
                Copyright 2022 © Agon - Agency Template.
                <br />
                Designed by
                <Link href="http://alithemes.com" legacyBehavior>
                  <a>&nbsp; AliThemes</a>
                </Link>
              </div> */}
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
};

export default Sidebar;
