import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react"; // No need to import Autoplay or Navigation
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const ServicesBar = (props) => {
  const { t } = useTranslation(); // Initialize translation hook

  let data = props[0].Data;
  let { ServiceTitle } = props[0].Info;

  return (
    <section className="section-box rtl">
      <div className="container mt-120">
        <div className="bg-2 bdrd-58 pattern-white pb-60">
          <div className="row">
            <div className="col-lg-2 col-sm-1 col-12" />
            <div className="col-lg-8 col-sm-10 col-12 text-center mt-70">
              <h2 className="text-heading-1 color-gray-900">{t("whatWeOffer")}</h2>
              <p className="text-body-lead-large color-gray-600 mt-20">
                {ServiceTitle}
              </p>
            </div>
            <div className="col-lg-2 col-sm-1 col-12" />
          </div>
          <div className="container mt-70">
            <>
              <div className="box-swiper">
                <div className="swiper-container swiper-group-4">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    loop={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    navigation={{
                      prevEl: ".swiper-button-prev-5",
                      nextEl: ".swiper-button-next-5",
                    }}
                    pagination={{ clickable: true }}
                    className="swiper-wrapper pb-70 pt-5"
                  >
                    {data.map((item, i) => (
                      <SwiperSlide key={i}>
                        <div className="swiper-slide">
                          <div className="card-grid-style-2 service-card hover-up">
                            <div className="grid-2-img">
                              <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  "/uploads/" +
                                  item.ImageUrl
                                }
                                alt={props.pageTitle}
                              />
                            </div>
                            <h3 className="text-heading-5 mt-20">
                              {item.TitleEn}
                            </h3>
                            <p className="text-body-text color-gray-600 mt-20">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.DescriptionEn,
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesBar;
