import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LogoBar1 = (props) => {
  const { t } = useTranslation(); // Initialize translation hook

  let { img1, img2, img3, img4, img5 } = props[0].Info;
  return (
    <section className="section-box overflow-visible mt-70">
      <div className="container">
        <h2 className="text-heading-3 text-center color-gray-900 mb-60">
          {t("trustedByLeadingCompanies")}
        </h2>
        <div className="row">
          <div className="col-lg-12">
            <ul className="list-partners">
              {[img1, img2, img3, img4, img5].map((img, index) => (
                <li key={index}>
                  <Link to="#">
                    <a className="item-logo box-hover-shadow">
                      <img alt="Al Noblaa" src={`${process.env.REACT_APP_API_URL}/${img}`} />
                    </a>
                  </Link>
                </li>
              ))}
              {[...Array(4)].map((_, index) => (
                <li key={5 + index}>
                  <Link to="#">
                    <a className="item-logo box-hover-shadow">
                      <img alt="Al Noblaa" src={`assets/imgs/slider/logo/sample-logo-${index + 2}.svg`} />
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogoBar1;
