import { Link } from 'react-router-dom';

const LogoBar2 = (props) => {
  let { img1, link1, img2, link2, img3, link3, img4, link4, img5, link5 } = props[0].Info;
  return (
    <div className="section-box overflow-visible mt-70">
    <div className="container">
        <div className="row justify-content-md-center">
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link to={link1} legacyBehavior><a className="item-logo hover-up"><img alt={link1}  src={`${process.env.REACT_APP_API_URL}/${img1}`} /></a></Link>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link to={link2} legacyBehavior><a className="item-logo hover-up"><img alt={link2}  src={`${process.env.REACT_APP_API_URL}/${img2}`} /></a></Link>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link to={link3} legacyBehavior><a className="item-logo hover-up"><img alt={link3}  src={`${process.env.REACT_APP_API_URL}/${img3}`} /></a></Link>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link to={link4} legacyBehavior><a className="item-logo hover-up"><img alt={link4}  src={`${process.env.REACT_APP_API_URL}/${img4}`} /></a></Link>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link to={link5} legacyBehavior><a className="item-logo hover-up"><img alt={link5}  src={`${process.env.REACT_APP_API_URL}/${img5}`} /></a></Link>
            </div>
            {/* <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <Link to={link1} legacyBehavior><a className="item-logo box-hover-shadow hover-up"><img alt="Al Noblaa" src="assets/imgs/slider/logo/sample-logo-6.svg" /></a></Link>
            </div> */}
        </div>
    </div>
</div>
  );
};

export default LogoBar2;
