import React from "react";

const BlogBanner = (props) => {
  let { aboutTitle } = props[0].Info;

  return (
    <></>
    // <div className="container mt-70">
    //   <div className="row">
    //     <div className="col-lg-1 col-sm-1 col-12" />
    //     <div className="col-lg-10 col-sm-10 col-12 text-center">
    //       <h2 className="text-heading-1 color-gray-900 mb-10">Our Blog</h2>
    //       <p className="text-heading-6 color-gray-600 mt-20">{aboutTitle}</p>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BlogBanner;
