import React from "react";

const MiddleService = (props) => {
  let data = props[0].Data;
  let { ServiceTitle } = props[0].Info;
  return (
    <section className="section-box mt-100 rtl">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-12">
            <div className="text-start mb-25">
              <span className="tag-1 bg-6 color-green-900">What We Do</span>
            </div>
            <h2 className="text-heading-2 color-gray-900 mb-50">
              {ServiceTitle}
            </h2>
          </div>
        </div>
      </div>
      <div className="container mt-20">
        <div className="row">
          {data.map((item, i) => (
            <div className="col-lg-3 col-md-6 col-sm-6 col-12"  key={item.Id}>
              <div className="list-icons rtl">
                <div className="item-icon none-bd">
                  <span className="icon-left">
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        "/uploads/" +
                        item.ImageUrl
                      }
                      alt={item.TitleEn}
                    />
                  </span>
                  <h4 className="text-heading-6">{item.TitleEn}</h4>
                  <p className="text-body-text color-gray-600 mt-15">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.DescriptionEn }}
                    />
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MiddleService;
