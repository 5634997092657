import { useState } from "react";

function Faq1(props) {
  const [isActive, setIsActive] = useState({
    status: false,
    key: 1,
  });

  const handleToggle = (key) => {
    if (isActive.key === key) {
      setIsActive({
        status: false,
      });
    } else {
      setIsActive({
        status: true,
        key,
      });
    }
  };
  let data = props[0].Data;

  return (
    <>
      <div className="accordion" id="accordionFAQ">
        {data.map((item, i) => (
          <div className="accordion-item"  key={item.Id}>
            <h2 className="accordion-header" onClick={() => handleToggle(1)}>
              <button
                className={
                  isActive.key == 1
                    ? "accordion-button text-heading-5 "
                    : "accordion-button text-heading-5 collapsed"
                }
              >
                {item.questionEn}
              </button>
            </h2>
            <div
              className={
                isActive.key == 1
                  ? "accordion-collapse collapse show"
                  : "accordion-collapse collapse "
              }
            >
              <div className="accordion-body">{item.answerEn}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Faq1;
