import React, { useState, useEffect } from "react";
import fetchAdditionalData from "./fetchAdditionalData";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Error404 from "../components/Error404";
const apiUrl = process.env.REACT_APP_API_URL;
const websiteId = process.env.REACT_APP_WEBSITE_ID;

const DynamicPage = ({ componentMap, pageName, pageId }) => {
  const [myPageConfig, setMyPageConfig] = useState([]);
  const [error404, setError404] = useState(false);
  const [metaData, setMetaData] = useState({
    title: "Al Noblaa Group - Leading Experts in Petroleum, Mining, Agriculture, and Transportation",
    description: "Al Noblaa Group is a trusted leader in petroleum distribution, mining solutions, agricultural exports, and transportation services. With a commitment to excellence and innovation, we deliver high-quality services to meet the demands of regional and international markets. Discover our expertise and industry-leading solutions.",
  });
  useEffect(() => {
    const fetchPageConfig = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/GetPage?websiteId=${websiteId}&pageName=${pageName}`
        );
        if (!response.data) setError404(true);
        var { titleEn, titleAr, descriptionEn, descriptionAr, config } =
        response.data;
        
        if(titleEn && descriptionEn)
          setMetaData({
        title: titleEn,
        description: descriptionEn,
      });
      
      const componentList = JSON.parse(config);
          console.log('yo yo', componentList);
        const updatedConfig = await Promise.all(
          componentList.map(async (item) => {
            if (item.Type) {
              const additionalData = await fetchAdditionalData(
                item.Info,
                item.Props,
                pageId
              );
              return { ...item, Props: [additionalData] };
            }
            return item;
          })
        );

        setMyPageConfig(updatedConfig);
      } catch (error) {
        console.error("Error fetching page config:", error);
      }
    };

    fetchPageConfig();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      {myPageConfig.length === 0 ? (
        error404 ? (
          <Error404 />
        ) : (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* Spinner */}
            <div className="spinner" style={{ marginRight: "10px" }}>
              <div className="double-spinner"></div> {/* Adding a spinner */}
            </div>

            {/* Logo */}
            <img
              style={{ filter: `grayscale(100%)`, height: "100px" }} // Adjust logo size if necessary
              src="./nLogo.png"
              alt="Loading..."
              className="loading-logo"
            />
          </div>
        )
      ) : (
        myPageConfig.map((item, index) => {
          const { ComponentName, Props } = item;
          const Component = componentMap[ComponentName];

          if (!Component) {
            console.warn(`Component "${ComponentName}" not found.`);
            return null;
          }

          const updatedProps = { ...Props, pageTitle: metaData.title };
          return <Component key={index} {...updatedProps} />;
        })
      )}
    </div>
  );
};

export default DynamicPage;
